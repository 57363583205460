import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/karott.png";
import Tilt from "react-parallax-tilt";
import Projects from "../Projects/Projects";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="hakkimizda">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.3em" }}>
              <span className="purple fw-bold">HAKKIMIZDA</span>
            </h1>
            <p className="home-about-body">
              "Sektördeki tecrübelerimizle yıllardır hizmet sunduğumuz alanlar
              arasında; karot beton delme, beton kesme ve beton kırma
              işlemlerinde profesyonel hizmet sunuyoruz. Osmaniye il ve çevre
              ilçelerinde hizmet ağımızı sürekli olarak geliştirmekteyiz.
              Teknolojik cihazlar ile birlikte hizmet kalitemizi her zaman üst
              seviyeye çıkartmaktayız. İnşaat sektöründeki varlığımızı,
              aldığımız projelerin sorunsuz teslimatı ve kalitesi ile
              kanıtlamaya devam ediyoruz. Her projede ayrım gözetmeksizin usta
              işçilik ve profesyonel hizmet sunma noktasında çaba sarf
              etmekteyiz. Tüm hizmetlerimizde ilk olarak müşterilerimizin
              beklentilerine ve ihtiyaçlarına yanıt vermek hedefimizdir. Uzman
              ekibimiz ile gerçekleştirdiğimiz projelerimizde iş sağlığı ve
              güvenliğini her zaman önceliğimiz kıldık. Aldığımız tedbirler ve
              ekibimize verilen eğitimler doğrultusunda yaşanabilecek kaza
              risklerini en aza indirgeme hedefinde her daim çalışmaya devam
              ediyoruz.Görmüş Karotun ana prensiplerinden olan kaliteli işçilik
              ve müşteri memnuniyeti ile gerçekleştirdiğimiz tüm projelerde elde
              ettiğimiz başarıların haklı gururu içerisindeyiz."
              <br />
              <br />
              <i>
                <b className="purple">Beton-Duvar Kırma:</b> "Beton kırma
                işlemi, sadece betonu kırmakla kalmaz, aynı zamanda çevredeki
                yapıların da zarar görmesine neden olabilir. Bu nedenle, beton
                kırma işlemi, özenle planlanmalı ve uygulanmalıdır. Öncelikle,
                betonun kırılması gereken bölgesi belirlenmeli ve bölgedeki
                diğer yapılar güvenli bir şekilde korunmalıdır. Beton kırma
                işlemi, inşaat sektöründe birçok farklı amaçla kullanılır.
                Örneğin, eski bir bina yıkılmadan önce, betonun kırılması
                gereken bölümleri belirlenir ve bu bölgelerde beton kırma işlemi
                uygulanır. Ayrıca, bir binanın yenilenmesi veya genişletilmesi
                sırasında da beton kırma işlemi yapılabilir."
              </i>
              <br />
              <br />
              <i>
                <b className="purple"> Beton-Duvar Kesme: </b> “ Bu yöntem,
                betonarme yapıların tamiratı, bakımı ve yenilenmesinde
                kullanılır. Ayrıca, elektrik, su ve kanalizasyon boruları gibi
                yapıların içine yerleştirilebilir. Karot beton kesme işlemi,
                profesyonel bir ekip tarafından yapılmalıdır. Çünkü, yanlış
                uygulandığında kesimde çatlaklar ve zararlar oluşabilir. Bu da,
                yapıya zarar verebilir. Ayrıca, bu işlem sırasında toz ve
                gürültü oluşur, bu nedenle doğru önlemler alınmalıdır. Sonuç
                olarak, karot beton kesme yöntemi, hassas kesimler yapmak için
                idealdir ve inşaat sektöründe yaygın olarak kullanılır. ”
              </i>
              <br />
              <br />
              <i>
                <b className="purple">Beton-Duvar Delme :</b> " Karot beton
                delme, elmas kaplı karot matkapları kullanarak yapılır. Bu
                matkaplar, beton veya sert malzemeleri delmek için özel olarak
                tasarlanmıştır. Matkap, sert yüzeyleri delmek için yüksek hızlı
                döner bir uca sahiptir. Bu işlem, su soğutma sistemi ile
                yapılır, böylece matkap soğuk kalır ve aşınmaz. Su, matkap ucuna
                doğrudan püskürtülür ve ayrıca delikte oluşan tozu da temizler.
                Karot beton delme yöntemi, farklı boyutlarda ve çeşitli
                şekillerde delikler açmak için kullanılabilir. Bu yöntem, hassas
                delikler açmak için idealdir ve betonarme yapıların bakımı,
                tamiri ve yenilenmesinde yaygın olarak kullanılır"
              </i>
            </p>
          </Col>
          <Col
            md={4}
            style={{ paddingTop: "50%", paddingBottom: "100px" }}
            className="myAvtar mt-5 "
          >
            <Tilt style={{ borderRadius: "50%" }}>
              <img src={myImg} className="img-av" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Projects />
      </Container>
    </Container>
  );
}
export default Home2;
