import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Kirma from "../../Assets/kirma.png";
import Delme from "../../Assets/delme.png";
import Kesme from "../../Assets/kesme.png";

function Projects() {
  return (
    <Container id="hizmetlerimiz" fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          <strong className="purple hizmet "> HİZMETLERİMİZ </strong>
        </h1>{" "}
        <br />
        <br />
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Kirma}
              isBlog={false}
              title=<span className="purple"> Beton Kırma </span>
              description="Biz, Görmüş Karot ekibi olarak güçlü ve hassas duvar kırma hizmetimizle buradayız!
               İhtiyaçlarınıza uygun çözümler sunarak projelerinizi ileriye taşıyoruz. Deneyimli ekibimiz ve modern
                ekipmanlarımızla duvarları kolayca kırıyor, işlemlerinizi hızlı, temiz ve profesyonel bir şekilde gerçekleştiriyoruz.
                Bizi şimdi arayın ve profesyonel hizmetimizin avantajını yaşayın!"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Delme}
              isBlog={false}
              title=<span className="purple"> Beton Delme </span>
              description=" Biz, Görmüş Karot ekibi olarak güçlü ekipmanlarımızla betonu delmek için buradayız! Hızlı ve 
                    etkili çözümler sunarak ihtiyaçlarınıza uygun hizmetler sağlıyoruz. Profesyonel kadromuz,
                   beton delme işlerinizi kolaylaştırmak adına sizinle birlikte çalışıyor. Güvenilir ve kaliteli hizmet 
                   anlayışımızla, projelerinizi ileriye taşıyoruz. Hemen bize ulaşın ve betonun üstesinden birlikte gelelim! "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Kesme}
              isBlog={false}
              title=<span className="purple"> Beton Kesme </span>
              description="Biz, Görmüş Karot ekibi olarak, güçlü kesme teknolojimizle betonu 
              mükemmel bir şekilde kesmek için buradayız! İnşaat projelerinize özel çözümler sunuyor, 
              hassas ve etkili kesimlerimizle işlerinizi ileriye taşıyoruz. Deneyimli ekibimiz, Beton Kesme
               ve Görmüş Karot işlemlerinizde kolaylık sağlamak ve yüksek kaliteli sonuçlar elde etmek için hazır. 
               Bizi şimdi arayın ve profesyonel hizmetimizin avantajını yaşayın!"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
