import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
  FaPhoneAlt,
} from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  return (
    <footer id="iletisim" className="footerr text-light py-4">
      <Container>
        <Row>
          {/* Sol Bölüm */}
          <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
            <div>
              <h4>Görmüş Karot</h4>

              <div className="footer-sociall  d-flex align-items-center justify-content-center fs-3">
                <a href="https://wa.me/+905306069584" className=" mx-2">
                  <FaInstagram />
                </a>
                <a href="https://wa.me/+905306069584" className=" mx-2">
                  <FaWhatsapp />
                </a>
                <a href="https://wa.me/+905306069584" className=" mx-2">
                  <FaFacebook />
                </a>
              </div>
              <div>
                <br />

                <p>Haftanın her günü 7/24 hizmetinizdeyiz</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <h4>İletişim</h4>
              <p>
                <FaPhoneAlt />
                <a className="text-white" href="tel:+905306069584">
                  +90 530 606 95 84
                </a>
              </p>

              <p>
                {" "}
                <HiLocationMarker /> Gökdere Mah. Yeniyerleşim Mevki 280/1{" "}
                <br />
                Erzin/HATAY
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <h4>Kurumsal</h4>
              <ul className="list-unstyled">
                <li>
                  <p>Anasayfa</p>
                </li>
                <li>
                  <p>Hakkımızda</p>
                </li>
                <li>
                  <p>Hizmetlerimiz</p>
                </li>
                <li>
                  <p>İletişim</p>
                </li>
              </ul>
            </div>
          </Col>
          <div className="footer-left-rights text-black d-flex align-item-center justify-content-center">
            <br />
            Copyright &#169;2024 Görmüş Karot - All Rights Reserved.
            <br />
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
