import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home.png";

import Home2 from "./Home2";

// react-typical modülünü import et
import Typical from "react-typical";

const Type = React.memo(() => {
  return (
    <Typical
      loop={Infinity}
      wrapper="p"
      steps={[
        "Osmaniye  İlinde",
        1000,
        "Erzin İlçesinde",
        1000,
        "Dörtyol İlçesinde",
        1000,
        
      ]}
      className="typing-text font-bold"
    />
  );
});

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
       

        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 10 }} className="heading">
                GÖRMÜŞ KAROT{" "}
                <span className="wave" role="img" aria-labelledby="wave"></span>
              </h1>

              <h1 className="heading-name">
                <strong className="main-name">
                  {" "}
                  Görmüş Karot olarak deneyimli ekibimizle birlikte,
                  karotçuluk mesleğinde, özellikle beton delme,kırma ve kesme
                  gibi tüm hizmetleri sizin için kapınıza getiriyoruz.
                  Karotçulukla ilgili tüm ihtiyaçlarınıza uygun fiyatlarla
                  kaliteli bir hizmet almak istiyorsanız bizi arayın.
                  Projelerinize özel çözümler sunmaktan memnuniyet duyan
                  tecrübeli ekibimizle sizlere yardımcı olmaktan mutluluk
                  duyarız.
                </strong>
              </h1>

              <div
                className="heading-slid "
                style={{ padding: 50, textAlign: "left" }}
              >
                <Type />
                <h1 className="heading-name">
                  <strong className="main-name">
                    hizmetlerimiz bulunmaktadır.
                  </strong>
                </h1>
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluidd"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
